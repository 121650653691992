<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card class='pb-5'>
          <template v-slot:headerTitle>
            <h3 class="card-title font-weight-bold" style='font-size: 30px'>Crear Cuenta</h3>
            <h5 class="card-title font-weight-bolder mt-4 mb-2" style='font-size: 20px'>General</h5>
            <p style='font-size: 1rem'>Aquí puede introducir toda la información relacionada con un plan contable.</p>
          </template>
          <form class='p-4' @submit.prevent="saveTipo">
            <div class="row">
              <div class="col-12 col-md-6 mt-3">
                <label for="Nombre">Nombre *</label>
                <b-form-input id="Nombre" name='Nombre' placeholder="Ingrese Nombre" required trim></b-form-input>
              </div>
              <div class="col-12 col-md-6 mt-3">
                <label for="CodigoClase">Código Clase *</label>
                <b-form-select name='CodigoClase' v-model="valorClase" :options='ListClase'
                  placeholder='-- Seleccionar un Tipo --' required>
                  <template #first>
                    <b-form-select-option value="null" disabled>-- Seleccionar un Tipo --</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <div class="col-12 col-md-6 mt-3">
                <label for="CodigoGrupo">Código Grupo *</label>
                <b-form-select name='CodigoGrupo' :options='ListGrupo' placeholder='-- Seleccionar un Tipo --' required
                  v-model="valorGrupo" :disabled='ListGrupo.length === 0'>
                  <template #first>
                    <b-form-select-option :value="null" disabled>-- Seleccionar un Tipo --</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <div class="col-12 col-md-6 mt-3">
                <label for="CodigoCuenta">Código Cuenta *</label>
                <b-form-select name='CodigoCuenta' :options='ListCuenta' placeholder='-- Seleccionar un Tipo --'
                  required v-model='valorCuenta' :disabled='ListCuenta.length === 0'>
                  <template #first>
                    <b-form-select-option :value="null" disabled>-- Seleccionar un Tipo --</b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <div class="col-12 col-md-6 mt-3">
                <label>Código SubCuenta *</label>
                <b-form-input id="Codigo" type='number' aria-describedby="input-live-help input-live-feedback"
                  placeholder="Crear Código SubCuenta" name='CodigoSubCuenta' v-model='CodigoSubCuenta2' :disabled='valorCuenta === null'
                  required trim></b-form-input>
                <p style='color: red'>{{ error }}</p>
              </div>
              <div class="col-12 mt-3">
                <label for="Descripcion">Descripción</label>
                <b-form-textarea id="Descripcion" required name='Descripcion' placeholder="Ingrese Descripción" rows='6'
                  trim></b-form-textarea>
              </div>
            </div>
            <div class='d-flex pt-4 justify-content-end'>
              <vs-button size="large" dark shadow @click.prevent="$router.push('Cuentas')">Cancelar</vs-button>
              <vs-button size="large" :loading="waiting" type="submit"
                :disabled="waiting || button === false">Guardar</vs-button>
            </div>
          </form>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import Swal from 'sweetalert2';
  import { core } from "../../../../../../config/pluginInit";

  export default {
    data() {
      return {
        waiting: false,
        error: null,
        ListClase: [],
        ListGrupo: [],
        ListCuenta: [],
        valorClase: null,
        valorGrupo: null,
        valorCuenta: null,
        CodigoSubCuenta2: null,
        EmpresaId: null,
        button: false,
        ClaseCuenta2: [],
        saveSubcuenta: {
          subCuenta: {
            NombreSubCuenta: '',
            CodigoSubCuenta: 0
          },
          claseCuentaId: "",
          personaId: "",
          descripcion: ""
        },
      };
    },
    async mounted() {
      const Persona = JSON.parse(localStorage.getItem('setPersonaAct'));
      this.saveSubcuenta.personaId = Persona.id;
      this.EmpresaId = Persona.empresa.id;
      core.index();
      await this.getData();
    },
    watch: {
      valorClase(newVal) {
        if (newVal) {
          this.getData();
        }
      },
      valorGrupo(newVal) {
        if (newVal) {
          this.getData();
        }
      },
      valorCuenta(newVal) {
        if (newVal) {
          this.getData();
        }
      },
      CodigoSubCuenta2(newVal) {
        if (newVal) {
          if (this.CodigoSubCuenta2.length !== 2) {
            this.error = 'El código de la SubCuenta debe tener 2 dígitos';
            this.button = false;
            return;
          }
          if (this.CodigoSubCuenta2.length === 2) {
            this.error = null;
            this.button = true;
          }
          this.getData();
        }
      }
    },
    methods: {
      async getData() {
        try {
          this.error = null;
          let clasecuenta = await this.$store.dispatch("hl_get", {
            path: 'DEClaseCuenta/ObtenerClaseCuenta',
          });
          let grupocuenta = await this.$store.dispatch("hl_get", {
            path: 'DEClaseCuenta/ObtenerGruposCuentasPorClase/' + this.valorClase,
          });
          let codigocuenta = await this.$store.dispatch("hl_get", {
            path: `DEClaseCuenta/ObtenerCuentasPorGrupoClase/${this.valorGrupo}/clase/${this.valorClase}`,
          });

          let verficarcodigosubcuenta = await this.$store.dispatch("hl_get", {
            path: `DESubCuenta/ConsultarSubCuentaExistenteCodigoYEmpresa/${this.saveSubcuenta.personaId}/codigoSubCuenta/${this.CodigoSubCuenta2}/Cuenta/${this.valorCuenta}`,
          });

          if (verficarcodigosubcuenta.respuesta === false) {
            this.button = false;
            this.error = 'El código de la SubCuenta ya existe';
            return;
          }

          if (verficarcodigosubcuenta.respuesta === true && this.CodigoSubCuenta2.length === 2) {
            this.button = true;
          }
          if (clasecuenta.data.length > 0) {
            this.ListClase = clasecuenta.data.map(item => ({
              value: item.codigoClase,
              text: `${item.codigoClase} - ${item.NombreClase}`
            }));
          } else {
            this.ListClase = [];
          }
          if (clasecuenta.data.length > 0) {
            this.ClaseCuenta2 = clasecuenta.data.map(item => ({
              value: item.id,
              text: item.codigoClase
            }));
          } else {
            this.ClaseCuenta2 = [];
          }

          if (grupocuenta.data.length > 0) {
            this.ListGrupo = grupocuenta.data.map(item => ({
              value: item.codigoGrupo,
              text: `${item.codigoGrupo} - ${item.NombreGrupo}`
            }));
          } else {
            this.ListGrupo = [];
          }

          if (codigocuenta.data.length > 0) {
            this.ListCuenta = codigocuenta.data.map(item => ({
              value: item.Id,
              text: `${item.CodigoCuenta} - ${item.NombreCuenta}`
            }));
          } else {
            this.ListCuenta = [];
          }
        } catch (error) {
          this.ListTipo = [];
        }
      },
      async saveTipo(event) {
        this.error = null;
        const data = Object.fromEntries(new FormData(event.target).entries());
        data.CodigoPug = parseInt(data.CodigoPug);

        if (!data.Nombre || data.CodigoSubCuenta < 0) {
          this.error = 'Todos los campos son obligatorios';
          return;
        }

        this.saveSubcuenta.subCuenta.NombreSubCuenta = data.Nombre;
        this.saveSubcuenta.subCuenta.CodigoSubCuenta = data.CodigoSubCuenta;
        this.saveSubcuenta.claseCuentaId = data.CodigoCuenta;
        this.saveSubcuenta.descripcion = data.Descripcion;

        try {
          this.waiting = true;
          const response = await this.$store.getters.fetchPost({
            path: `DESubCuenta/CrearSubCuenta`,
            data: this.saveSubcuenta
          })
          if (response.ok) {
            Swal.fire({
              title: 'Cuenta creada',
              text: 'Se ha guardado la Cuenta',
              icon: 'success',
              confirmButtonText: 'Aceptar'
            }).then(() => {
              this.$router.push('Cuentas');
              event.target.reset();
            });
          } else {
            throw new Error("No se ha guardado la Cuenta");
          }
        } catch (error) {
          Swal.fire({
            title: 'Error',
            text: 'No se ha guardado la Cuenta',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
        } finally {
          this.waiting = false;
        }
      },
    }
  }
</script>